<template>
  <div>
    <nuxt-link :to="localePath('/')">
      <img :src="`/images/${imagePath}.gif`" alt="dapp expert logo" :class="logoClasses" />
    </nuxt-link>
  </div>
</template>

<script setup lang="ts">
import { useLocalePath } from '#i18n';
import type { PropType } from 'vue';
import { computed } from 'vue';
import {
  APP_LOGO_SIZE_OPTIONS,
  APP_LOGO_VARIANT_OPTIONS,
} from '~/shared/components/logo/app-logo.config';
import type {
  AppLogoSizeOptions,
  AppLogoVariantOptions,
} from '~/shared/components/logo/app-logo.interface';

const props = defineProps({
  variant: {
    type: String as PropType<AppLogoVariantOptions>,
    default: APP_LOGO_VARIANT_OPTIONS.default,
    validator: (value: string) =>
      Object.values(APP_LOGO_VARIANT_OPTIONS).includes(value as AppLogoVariantOptions),
  },
  size: {
    type: String as PropType<AppLogoSizeOptions>,
    default: APP_LOGO_SIZE_OPTIONS.small,
  },
});

const localePath = useLocalePath();

const imagePath = computed(() => {
  return `logos/logo-${props.variant}`;
});

const logoClasses = computed(() => {
  switch (props.size) {
    case APP_LOGO_SIZE_OPTIONS.small:
      return 'tw-w-750 lg:tw-w-950 tw-h-auto';
    case APP_LOGO_SIZE_OPTIONS.large:
      return 'tw-w-4500 lg:tw-w-5750 tw-h-auto';

    default:
      throw new Error('Unknown app logo size value ' + props.size);
  }
});

// const srcSet = computed(() => {
//   const imagePathDefault = require(`/images/${imagePath.value}.png`);
//   const imagePath2x = require(`/images/${imagePath.value}@2x.png`);
//   const imagePath3x = require(`/images/${imagePath.value}@3x.png`);
//
//   if (props.size === APP_LOGO_SIZE_OPTIONS.normal)
//     return `${imagePathDefault} 180w, ${imagePath2x} 360w, ${imagePath3x} 540w`;
//
//   return `${imagePathDefault} 72w, ${imagePath2x} 144w, ${imagePath3x} 216w`;
// });
</script>
